import React, { useEffect, useState } from 'react';
import { SupabaseFunctions } from '../supaBaseClient';

const ProductTable = ({ products }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [dollarFloat, setDollarFloat] = useState(0);

  useEffect(() => {
    GetTotalPrice(products);
  }, [products]);

  const handleSelectProduct = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(product)
        ? prevSelected.filter((p) => p !== product)
        : [...prevSelected, product]
    );
  };

  const handleSellItems = () => {
    if (selectedProducts.length === 0) {
      window.alert("Please select some items to sell");
      return;
    }

    console.log('Selected Products:', selectedProducts);
    selectedProducts.forEach((product) => {
      SupabaseFunctions.MarkProductResell(product.id);
    });

    window.open('https://www.usekuai.com/itemsforsale', '_blank', 'noopener,noreferrer');

  };

  const extractDollarValue = (text) => {
    if (!text) {
      return 0; // Return 0 if text is null or undefined
    }
    const regex = /\$([0-9,.]+)/;
    const match = text.match(regex);

    if (match) {
      const dollarValue = match[1];
      return parseFloat(dollarValue);
    } else {
      console.log("No dollar value found");
      return 0;
    }
  };

  const GetTotalPrice = (products) => {
    const total = products.reduce((acc, product) => acc + extractDollarValue(product.est_price), 0);
    setDollarFloat(total);
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* Sell Items Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSellItems}
          className="w-full sm:w-1/4 bg-black text-white py-2 px-6 text-base font-normal rounded-2xl shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 flex items-center justify-center gap-2 transition-colors"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
          </svg>
          Sell Items
        </button>
      </div>

      {/* Total Value Card */}
      <div className="flex justify-end">
        <div className="w-full sm:w-1/4 bg-gray-50 rounded-3xl p-8 py-2 mt-1">
          <div className="flex flex-col items-center justify-end">
            <h2 className="text-gray-600 text-lg font-normal mb-2">Total Estimated Value</h2>
            <p className="text-3xl font-normal text-black">
              ${dollarFloat.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

{/* Product Table Headers */}
<div className="grid grid-cols-[1fr_2fr_1fr] gap-6 px-6 py-3">
  <div className="text-md font-normal text-gray-500">Image</div>
  <div className="text-md font-normal text-gray-500">Product Details</div>
  <div className="text-md font-normal text-gray-500 text-right">Value</div>
</div>

{/* Product List */}
<div className="space-y-3">
  {products.map((product, i) => {
    const isSelected = selectedProducts.includes(product);
    return (
      <div
        key={i}
        className={`grid grid-cols-[1fr_2fr_1fr] gap-6 px-6 py-4 bg-white rounded-xl border ${
          isSelected ? 'border-black bg-gray-50' : 'border-gray-100 hover:bg-gray-50'
        } transition-colors`}
        onClick={() => handleSelectProduct(product)}
      >
        {/* Image */}
        <div className="flex items-center gap-3">
          <div className="w-5">
            {isSelected && (
              <div className="hidden sm:flex w-5 h-5 bg-black text-white rounded-full flex items-center justify-center text-sm">
                ✓
              </div>
            )}
          </div>
          <img
            src={product.image_url}
            alt={product.name}
            className="w-32 h-32 object-cover rounded-lg"
          />
        </div>

        {/* Product Details */}
        <div className="flex items-center text-base text-gray-900">
          {product.sender} - {product.product_name}
        </div>

        {/* Price */}
        <div className="flex items-center justify-end text-base font-normal text-gray-900 text-right">
          {product.est_price}
        </div>
      </div>
    );
  })}
</div>
    </div>
  );
};

export default ProductTable;