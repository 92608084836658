// src/gmailReader.js

import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import { htmlToText } from "html-to-text";
import { SupabaseFunctions } from "./supaBaseClient";
import pako from 'pako';
import axios from 'axios'; 
import ProductTable from "./Components/ProductTable";


const CLIENT_ID = "582474056728-bfp7tl0rq0n1m1q660go2odfi56a3rsa.apps.googleusercontent.com";
const API_KEY = "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";

// const CLIENT_ID = "779347329493-ji8a0omvkdkib9mr4ki4bptfasssg6b5.apps.googleusercontent.com";
// const API_KEY = "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";
const SCOPES = "https://www.googleapis.com/auth/gmail.readonly";

const GmailReader = () => {
  const [emails, setEmails] = useState([]);
  const [products, setProducts] = useState([]); // Add state for products
  const [loading, setLoading] = useState(false); // Add loading state
  const [isSignedIn, setIsSignedIn] = useState(false); // Add state for sign-in status
  const [authLoading, setAuthLoading] = useState(true); // Add state for loading authentication status
  const [refreshing, setRefreshing] = useState(false); // Add state for showing the "Refreshing products" popup
  const [userName, setUserName] = useState(localStorage.getItem('name') || 'User');
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false); // New state for first-time login


  useEffect(() => {
    const initializeGapi = () => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
          scope: SCOPES,
        }).then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          const signedIn = authInstance.isSignedIn.get();
          setIsSignedIn(signedIn);
          setAuthLoading(false); // Set loading to false after determining sign-in status
  
          if (signedIn) {
            // Fetch products if the user is already signed in
            fetchUserData();
          }
  
          authInstance.isSignedIn.listen(setIsSignedIn);
        });
      });
    };
    initializeGapi();
  }, []);

  const handleAuthClick = async () => {
    try {
      setLoading(true);
      // Sign in using Google API
      const googleAuth = await gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' });
      
      // Get the authenticated user's basic profile
      const profile = googleAuth.getBasicProfile();
      const email = profile.getEmail();
      const name = profile.getName();
      const imageUrl = profile.getImageUrl();
  
      // Update userName state immediately
      setUserName(name);
  
      // Store user details in localStorage
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('imageUrl', imageUrl);
  
      // Check if the user exists in Supabase
      await SupabaseFunctions.GetUser(email);
      let userId = localStorage.getItem('userId');
  
      // Check if this is the user's first login
      const isFirstLogin = !userId;
      setIsFirstTimeLogin(isFirstLogin);
  
      if (isFirstLogin) {
        await SupabaseFunctions.CreateUser(email);
        userId = localStorage.getItem('userId');
        
        // For first login, wait for email processing to complete
        await fetchEmails();
        
        // Now fetch products AFTER email processing is done
        const products = await SupabaseFunctions.GetProducts();
        setProducts(products);
      } else {
        // For returning users, just fetch existing products
        const products = await SupabaseFunctions.GetProducts();
        setProducts(products);
      }
    } catch (err) {
      console.error('Error during authentication:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchLatestEmails = async () => {
    setRefreshing(true); // Show the popup
    console.log("Emails Fetched");
    let latestdate = await SupabaseFunctions.GetProductFetchDate();

    if (typeof latestdate === "string" || typeof latestdate === "number") {
      //  latestdate = new Date(latestdate); UNCOMMENT OUT LATER
    }

    console.log(latestdate);
    await fetchEmails(latestdate);
    await SupabaseFunctions.UpdateProductFetchDate();
    setRefreshing(false); // Hide the popup after refreshing is complete
  };

  // Function to clean the email body
  const cleanEmailBody = (body) => {
    // Array to store image URLs
    const imageUrls = [];
  
    // Regex to match and store image URLs
    body = body.replace(/<img[^>]+src="([^">]+)"/g, (match, src) => {
      imageUrls.push(src);
      return `[IMG_${imageUrls.length - 1}]`; // Placeholder for image URLs
    });
  
    // Remove all HTML tags while preserving image placeholders
    body = body.replace(/<[^>]*>/g, '');
  
    // Replace common HTML entities with their character equivalents
    body = body.replace(/&nbsp;/g, ' ')
               .replace(/&amp;/g, '&')
               .replace(/&quot;/g, '"')
               .replace(/&lt;/g, '<')
               .replace(/&gt;/g, '>')
               .replace(/&#39;/g, "'");
  
    // Remove typical email header and footer sections
    const headerFooterPatterns = [
      /thank you for your order/i,         // "Thank you for your order" heading
      /follow us on/i,                     // Footer with social media or newsletter links
      /you might also like/i,              // Product recommendations or ads
      /recommended products/i,
      /customer service/i,                 // Contact information at the footer
      /questions\? contact us/i,
      /view this email in your browser/i,   // Links to view the email online
      /unsubscribe/i,                      // Unsubscribe links
    ];
  
    headerFooterPatterns.forEach((pattern) => {
      body = body.replace(pattern, '');
    });
  
    // Focus on receipt-related content by narrowing the extraction
    const receiptMarkers = {
      start: /order summary|your order details|order confirmation|items ordered/i,
      end: /total:|shipping address|billing address|payment method/i
    };
  
    // Try to extract content between start and end markers
    const startIndex = body.search(receiptMarkers.start);
    const endIndex = body.search(receiptMarkers.end);
  
    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      body = body.substring(startIndex, endIndex);
    }
  
    // Trim excessive whitespace and reduce to single spaces
    body = body.replace(/\s+/g, ' ').trim();
  
    // Re-insert image URLs into their respective placeholders
    imageUrls.forEach((url, index) => {
      body = body.replace(`[IMG_${index}]`, `<img src="${url}">`);
    });
  
    return body;
  };

  const fetchEmails = async (lastfetchdate) => {
    setLoading(true);
    try {
      console.log("Fetching emails after:", lastfetchdate);
      const allMessages = await getAllMessages(lastfetchdate);
      await processEmailsInBatches(allMessages);
    } catch (error) {
      console.error("Error in fetchEmails:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    const user = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    const email = user.getEmail();
    const name = user.getName();
    const imageUrl = user.getImageUrl();
  
    localStorage.setItem('imageUrl', imageUrl);
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
  
    try {
      // Fetch products after sign-in or page refresh
      const products = await SupabaseFunctions.GetProducts();
      setProducts(products);
    } catch (error) {
      console.error("Error fetching products after sign-in or refresh:", error);
    }
  };

  // Step 1: Get all messages based on the query and pagination
  const getAllMessages = async (lastfetchdate) => {
    let allMessages = [];
    let nextPageToken = null;
    const query = constructQuery(lastfetchdate);

    do {
      const params = {
        userId: "me",
        q: query,
        maxResults: 100,
        pageToken: nextPageToken,
      };

      const response = await makeGmailApiCall("list", params);
      if (response.result.messages) {
        allMessages = allMessages.concat(response.result.messages);
      }

      nextPageToken = response.result.nextPageToken;
    } while (nextPageToken);

    console.log("Total Messages Retrieved:", allMessages.length);
    return allMessages;
  };

  // Step 2: Construct the query based on keywords and date filters
  const constructQuery = (lastfetchdate) => {
    const keywords = [
        "info@loveshackfancy.com",
        "talktomebaby@realisationpar.com",  
        "info@forloveandlemons.com",
        "info@tx.pacsun.com",
        "ganni@orders.ganni.com",
        "ref@email.thereformation.com",
        "contact@djerfavenue.com",
        "infos@news.sezane.com",
        "noreply@zara.com",
        "online.store@skims.com",
        "no-reply@mail.selfridges.com",
        "Aritzia@e.aritzia.com",
        "orders@e.abercrombie.com",
        "freepeople@s.freepeople.com",
        "anthropologie@s.anthropologie.com",
        "us@delivery.hm.com",
        "store+54532276409@t.shopifyemail.com",
        "store+24490770509@t.shopifyemail.com",
        "sales@t.revolve.com",
        "hi@princesspolly.com"
    ];
    let timestamp;
    // if (lastfetchdate) {
    //   timestamp = Math.floor(lastfetchdate.getTime() / 1000);
    // }
    // else {
      const today = new Date();
      const twoYearsAgo = new Date(today.setFullYear(today.getFullYear() - 2));
    timestamp = Math.floor(twoYearsAgo.getTime() / 1000);
    // }
    const query = `${keywords.map(email => `from:${email}`).join(" OR ")} after:${timestamp}`;
    console.log("Constructed Query:", query);  // Log the query to ensure it's correct
    return query;
  };

  // Step 3: Process the messages in batches to avoid API limits
  const processEmailsInBatches = async (allMessages) => {
    const maxBatchSize = 20; // Reduce batch size to decrease the number of requests per batch
    let allFilteredEmails = [];
  
    for (let i = 0; i < allMessages.length; i += maxBatchSize) {
      const chunk = allMessages.slice(i, i + maxBatchSize);
      let batch = gapi.client.newBatch();
  
      // Add all the individual message requests to the batch
      chunk.forEach((message) => {
        batch.add(
          gapi.client.gmail.users.messages.get({
            userId: "me",
            id: message.id,
          })
        );
      });
  
      // Process the batch with exponential backoff and jitter if a rate limit is hit
      try {
        const batchResponse = await executeBatchWithBackoff(batch);
        
        // Collect all the individual responses from the batch
        const batchResults = Object.values(batchResponse.result);
        console.log("Batch Results:", batchResults);
        
        // Now we process each email in batchResults
        const filteredEmails = filterAndProcessEmails(batchResults);
        
        // Store the filtered emails in state and in allFilteredEmails
        setEmails((prevEmails) => [...prevEmails, ...filteredEmails]);
  
        // Collect filtered emails to send to backend later
        allFilteredEmails = allFilteredEmails.concat(filteredEmails);
        
      } catch (error) {
        console.error("Batch processing failed after retries:", error);
      }
    }
  
    // After processing all batches, send filtered emails to the backend
    if (allFilteredEmails.length > 0) {
      console.log("Sending filtered emails to backend...");
      await sendEmailsToBackend(allFilteredEmails);
    }
  };
  
  // Function to handle batch execution with exponential backoff, jitter, and retry-after handling
  const executeBatchWithBackoff = async (batch, retries = 5, delay = 1000) => {
    try {
      // Execute the batch request
      const response = await batch;
      return response;
    } catch (error) {
      if (error.code === 429 && retries > 0) {
        const retryAfter = error.headers?.['Retry-After'] || delay; // Check if the Gmail API returned a retry-after header
        const jitter = Math.random() * 1000; // Introduce some random jitter (in milliseconds)
        const newDelay = parseInt(retryAfter, 10) * 1000 + jitter;
  
        console.warn(`Rate limit exceeded during batch execution. Retrying in ${newDelay} ms...`);
        await new Promise((resolve) => setTimeout(resolve, newDelay)); // Wait before retrying
        return executeBatchWithBackoff(batch, retries - 1, delay * 2); // Retry with exponential backoff
      } else {
        console.error("Batch execution failed after retries:", error);
        throw error;
      }
    }
  };
  
  

  const filterAndProcessEmails = (batchResults) => {
    const subjects = [
      /Your order #[A-Za-z0-9]+ has been .*/i,
      /Got It! Thanks For Your Order.*/i,
      /Thanks For Your Order!/i,
      /Order [A-Za-z0-9]* confirmed/i,
      /Order #[A-Za-z0-9]* confirmed/i,
      /Your [A-Za-z0-9]* Order Confirmation*/i,
      /Your .+ Order Confirmation.*/i,
      /.*Confirmation of your Order .*[A-Za-z0-9]+/i,
      /.*Order Confirmation.*/i,
      /.*Your [A-Za-z0-9]* order .*[A-Za-z0-9]+/i,
      /Your Order [A-Za-z0-9]+ Is Confirmed!/i,
      /.*Order Confirmation/i,
      /Thank you for your purchase/i,
      /Thank you for your order */i,
      /Your Order .+ Is Confirmed!*/i,
      /Your Order #[A-Za-z0-9]* Is Confirmed!*/i,
      /We've received your order.*/i,
    ];
  
    return Object.values(batchResults)
      .map((msgResponse) => {
        const msg = msgResponse.result;
        console.log("Processing email:", msg);
        if (!msg || !msg.payload) return null;
  
        const headers = msg.payload.headers || [];
        const subject = headers.find((header) => header.name === "Subject")?.value;
        const fromHeader = headers.find((header) => header.name === "From")?.value;
        const dateHeader = headers.find((header) => header.name === "Date")?.value;
  
        console.log("Subject:", subject);
        let emailBodyHtml = "";
  
        try {
          // Process the email parts, focusing only on HTML
          if (msg.payload.parts && msg.payload.parts.length > 0) {
            msg.payload.parts.forEach((part) => {
              if (part.mimeType === "text/html" && part.body.data) {
                emailBodyHtml = atob(part.body.data.replace(/-/g, "+").replace(/_/g, "/"));
              } else if (part.mimeType === "multipart/alternative") {
                // Recursively handle multipart/alternative, focusing on HTML
                part.parts.forEach((altPart) => {
                  if (altPart.mimeType === "text/html" && altPart.body.data) {
                    emailBodyHtml = atob(altPart.body.data.replace(/-/g, "+").replace(/_/g, "/"));
                  }
                });
              }
            });
          }
  
          // Check the payload body if no HTML part was found in parts
          if (!emailBodyHtml && msg.payload.body && msg.payload.body.size > 0) {
            if (msg.payload.mimeType === "text/html") {
              emailBodyHtml = atob(msg.payload.body.data.replace(/-/g, "+").replace(/_/g, "/"));
             // console.log("emailBodyHtml", emailBodyHtml)
            }
          }
        } catch (error) {
          console.warn("Failed to decode base64 content:", error);
          return null; // Skip this email if decoding fails
        }
       // console.log("is there emailBodyHtml", emailBodyHtml)
        // If no HTML body is found, skip the email
        if (!emailBodyHtml) {
          console.warn("No HTML body found for message:", msg);
          return null;
        }
        emailBodyHtml = cleanEmailBody(emailBodyHtml)
        // Apply regex to filter by subject
        const containsSubjectString = subjects.some((regex) => regex.test(subject));
  
        if (containsSubjectString) {
          return {
            id: msg.id,
            snippet: msg.snippet,
            subject,
            from: fromHeader,
            date: dateHeader,
            bodyHtml: emailBodyHtml,  // Return HTML body only
          };
        } else {
          console.log(`Skipping email: ${subject} - Does not meet criteria`);
          return null;
        }
      })
      .filter((email) => email !== null); // Filter out null values
  };
  // Function to make Gmail API calls with exponential backoff
  const makeGmailApiCall = async (method, params, retries = 5, delay = 1000) => {
    try {
      console.log('Making Gmail API call:', method, params);
      if (method === "list") {
        return await gapi.client.gmail.users.messages.list(params);
      } else if (method === "get") {
        return await gapi.client.gmail.users.messages.get(params);
      }
    } catch (error) {
      console.error('Error caught in makeGmailApiCall:', error);
      if (error.code === 429 && retries > 0) {
        console.warn(`Rate limit exceeded. Retrying in ${delay} ms...`);
        console.log('Retries left', retries);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return makeGmailApiCall(method, params, retries - 1, delay * 2);
      } else {
        console.error("API call failed:", error);
        throw error;
      }
    }
  };


  const sendEmailsToBackend = async (filteredEmails) => {
    try {
      // Convert the emailData (JSON) to a string
      const jsonString = JSON.stringify(filteredEmails);
      // Gzip the JSON string using pako
      const compressedData = pako.gzip(jsonString);
//    comment and uncomment this area
      // const response = await axios.post(
      //   'http://localhost:8000/extract_products', compressedData ,
      //   {
      //     headers: {
      //       'Content-Type': 'application/gzip'
      //     },
      //     responseType: 'json',
      //   }
      // );
      const response = await axios.post(
        'https://chia-be.onrender.com/extract_products', compressedData ,
        {
          headers: {
            'Content-Type': 'application/gzip'
          },
          responseType: 'json',
        }
      );
      const newProducts = response.data.products;
      // Append new products to the existing products in the state
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);

      // Save the new products to Supabase
      SupabaseFunctions.CreateProducts(newProducts);
      console.log(response.data);
      // return response;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogout = () => {
    gapi.auth2.getAuthInstance().signOut().then(() => {
      localStorage.clear();
      setProducts([]); // Clear products on logout
    });
  };

  const UserProfile = ({ handleLogout }) => {
    const fullName = localStorage.getItem("name");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };

    return (
      <div className="relative inline-block text-left">
        <button
          onClick={toggleDropdown}
          className="inline-flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
        >
          <span className="text-md font-semibold">
            {fullName ? fullName.split(" ")[0] : "User"}
          </span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {dropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md bg-white focus:outline-none">
            <div className="py-1">
              <button
                onClick={handleLogout}
                className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const LoadingMessage = () => (
    <div className="flex flex-col items-center justify-center space-y-4 p-8">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      <p className="text-lg text-gray-700 text-center">
        Welcome to KUAI! We're analyzing your purchase history to find resellable items...
      </p>
      <p className="text-sm text-gray-500 text-center">
        This might take a few minutes for first-time users
      </p>
    </div>
  );

  return (
    <div className="min-h-screen bg-white flex flex-col items-center py-4 px-4 sm:py-10">
      <div className="w-full max-w-6xl space-y-8">
        {/* Header with logo and profile/connect button */}
        <div className="flex justify-between items-center mb-12 sticky top-0 bg-white z-10">
          {/* Logo */}
          <div className="flex-shrink-0">
            <h1 className="text-4xl font-normal tracking-wider">
              KUAI
            </h1>
          </div>

          {/* Right side: Profile or Connect Gmail */}
          <div className="flex items-center space-x-2.5">
            {isSignedIn ? (
              // When logged in, show both Refresh and Profile
              <>
                <button
                  onClick={fetchLatestEmails}
                  className="bg-black text-white py-2 px-4 text-sm font-normal rounded-2xl shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors flex items-center gap-2"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Refresh Emails
                </button>
                <UserProfile handleLogout={handleLogout} />
              </>
            ) : (
              // When not logged in, show only Connect Gmail
              <button
                onClick={handleAuthClick}
                className="bg-black text-white py-2 px-4 text-sm font-normal rounded-2xl shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors flex items-center gap-2"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                Connect Gmail
              </button>
            )}
          </div>
        </div>
        
        <div className="w-full">
  {loading && isFirstTimeLogin ? (
    <LoadingMessage />
  ) : (
    <>
      {refreshing && (
        <div className="text-gray-600 text-center py-2 bg-gray-50 rounded-md mb-4">
          Refreshing products...
        </div>
      )}
      {products && products.length > 0 ? (
        <ProductTable products={products} />
      ) : (
        <p className="text-gray-600 text-center mt-6 px-4">
          {products === null 
            ? "Error loading products. Please try again." 
            : "Connect your Gmail to discover resellable items"}
        </p>
      )}
    </>
  )}
</div>
      </div>
    </div>
  );
};

export default GmailReader;